import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import Loadable from 'react-loadable';
import window from 'window-or-global';
import cookie from 'react-cookie';
import { addFacetsMargin, removeFacetsMargin } from 'plp/components/ProductListPage/components/FacetedLeftNav/actions';
import { getPlaceholderValue } from '../Search/search-reducers';
import { fetchSilosForNewMobileNav } from '../../Navigation/actions-navigation';
import '@nmg/ui-header/build/main.css';
import './HeaderWrapper.scss';
import RenderContentItem from 'cms/components/renderContentItem';
import RenderLayout from 'cms/components/renderLayout';
import RenderGlobalContent from 'cms/components/renderGlobalContent';
import { setHeaderState, setGender } from 'shared/components/App/app-actions';

const NMHeader = Loadable({
  loader: () => import('@nmg/ui-header'),
  loading: () => false,
});


const CN = 'header-wrapper';

export class ConnectedHeaderWrapper extends Component {
  constructor(props) {
    super(props);
    const { isHeaderSticky, isMobilePhone } = props;

    this.isStickyHeaderOn = isHeaderSticky && !isMobilePhone;
    this.state = {
      prevScrollPosition: window.pageYOffset,
      visible: true,
      isTop: true,
    };
  }

  componentDidMount() {
    const {ecmTickerRef, setHeaderState} = this.props;
    const height = ecmTickerRef?.current ? ecmTickerRef.current.clientHeight : 0;
    setHeaderState({ tickerHeight: height });
    this.isStickyHeaderOn && window.addEventListener('scroll', throttle(this.handleScroll, 300));
  }

  componentWillUnmount() {
    this.isStickyHeaderOn && window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollPosition } = this.state;
    const { facetsMargin } = this.props;

    const currentScrollPosition = window.pageYOffset;
    const visible = prevScrollPosition > currentScrollPosition;

    if (visible) {
      if (!facetsMargin) {
        this.props.addFacetsMargin();
      }
    }
    if (!visible) {
      if (facetsMargin) {
        this.props.removeFacetsMargin();
      }
    }

    this.setState({
      prevScrollPosition: currentScrollPosition,
      visible,
      isTop: currentScrollPosition === 0,
    });
  };

  render() {
    const { visible, isTop } = this.state;
    const {
      isDarkMode,
      tickerComponent,
      ecmTickerRef,
      isMobilePhone,
      isHeaderSticky,
      selectedGender,
      topProducts,
      desktopNavigationSilos,
      mobileNavigationSilos,
      templateDetails,
      typeaheadUIConfig,
      profile_data,
      toggles,
      device,
      abTestsOpt,
      countryCode,
      cmsContent,
      templates,
      totalnoOfItemsInCart,
      placeholder,
      setHeaderState,
      fetchSilosForNewMobileNav,
      isDomestic,
      setGender,
      storeName,
    } = this.props;
    const abTestsOptData = {
      ...abTestsOpt,
      ...{
        nmsw0001: {
          variation: 'b',
        },
      },
    };
    const ucaProfileData = cookie.load('ucaProfileData');
    const profileData = cookie.load('profile_data');
    return (
      <>
        {
          !isMobilePhone && (!isHeaderSticky || (isHeaderSticky && isTop)) && tickerComponent
        }

        <div
          className={classNames(`${CN}--scrollableHeader`, {
            'header-wrapper': !isMobilePhone,
            'dark-mode': isDarkMode,
            [`${CN}--sticky`]: this.isStickyHeaderOn,
            [`${CN}--shadowed`]: this.isStickyHeaderOn && visible && !isTop
          })}
        >
          <div className="grid-container large grid-parent">

            <NMHeader
              headerProps={{
                user: { name: profile_data?.name|| ucaProfileData?.name || profileData?.name},
                abTestsOpt: abTestsOptData,
                NMConfig: global.NMConfig,
                topProducts,
                typeaheadUIConfig,
                initialGender: selectedGender,
                tickerComponent: device?.isMobile && (
                  <div ref={ecmTickerRef}>
                    <RenderGlobalContent cmsContent={cmsContent} placement="SiteTicker" />
                  </div>
                ),
                searchPlaceHolder: placeholder,
                setHeaderState: setHeaderState,
                setGender: setGender,
                storeName: storeName
              }}
              navigationProps={{
                ...templateDetails,
                desktopNavigationSilos,
                mobileNavigationSilos,
                pageId: 'PAGE_ID_PLP',
                cmsContent,
                renderContentItem: RenderContentItem,
                renderLayout: RenderLayout,
                templates,
                fetchSilosForNewMobileNav
              }}
              device={device}
              appConfig={{
                toggles: {
                  ...toggles,
                  ADD_TO_CART_V2: toggles?.ADD_TO_CART_V2 && isDomestic,
                },
                profileData: { ...profile_data, countryPreference: countryCode , cartItemCount: totalnoOfItemsInCart },
                abTestsOpt: abTestsOptData,
              }}
            />

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobilePhone: get(state, 'device.isMobilePhone', false),
    isHeaderSticky: get(state, 'toggles.STICKY_HEADER_DESKTOP', false),
    hideMainNavToggle: get(state, 'toggles.HIDE_MAIN_NAV', false),
    facetsMargin: get(state, 'facetedLeftNav.facetsMargin', false),
    topProducts: get(state, 'srp.search.topProducts', []),
    desktopNavigationSilos: get(state, 'navigation.silos', []),
    mobileNavigationSilos: get(state, 'mobileNav.silos', []),
    templateDetails: get(state, 'templates.templateDetails', {}),
    toggles: get(state, 'toggles', {}),
    device: get(state, 'device', {}),
    profile_data: get(state, 'user', {}),
    typeaheadUIConfig: get(state, 'search.typeaheadUIConf', {}),
    abTestsOpt: get(state, 'abTestsOpt', {}),
    topProducts: get(state, 'search.topProducts', []),
    countryCode: get(state, 'locale.countryCode', 'US'),
    cmsContent: get(state, 'cms.global', {}),
    templates: get(state, 'templates', {}),
    totalnoOfItemsInCart: get(state, 'miniCart.noOfItemsInCart'),
    placeholder: getPlaceholderValue(state),
    isDomestic: get(state, 'locale.countryCode', 'US') === 'US',
    storeName: get(state, 'facetedLeftNav.storeName', ''),
  };
};

const mapDispatchToProps = ({
  addFacetsMargin,
  removeFacetsMargin,
  setHeaderState,
  fetchSilosForNewMobileNav,
  setGender
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedHeaderWrapper);
